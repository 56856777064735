@import url("satoshi.css");

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .coming-soon {
    display: block;
  }
}

@media (min-width: 769px) {
  .coming-soon {
    display: none;
  }
}
