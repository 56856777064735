/* src/index.css */
/* ... (existing styles) ... */

.coming-soon {
  font-size: 50px;
  text-align: center;
  width: 100%;
  padding-top: 50px;
}

/* .mega-meth-section {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
} */
